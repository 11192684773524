<template>
  <!-- Card with button to delete item top right, image of csv in the center and filename under the image with size of the file between parentheses-->
  <v-container fluid>
    <v-card :max-width="$vuetify.breakpoint.xs ? '200': '150'">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon color="error" @click="$emit('delete-file', file)">
          <v-icon>mdi-close-box</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row :align="'center'">
            <v-col :align="'center'">
                <v-img src="@/assets/blockchain/GENERIC.svg" height="50" width="50" contain></v-img>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <b>{{ file.fileName }} </b>({{ file.fileSize / 1000 }} kB)
            </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
    name: "csv-card",
    props: {
        file: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>