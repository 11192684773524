<template>
  <v-container fluid>
    <v-row :align="'start'" align-content=start :justify="'start'" no-gutters v-if="!$vuetify.breakpoint.xs" >
      <v-col v-for="(file, i) in files" :key="i" cols="3" lg="3" md="4" sm="6" xs="12">
        <csv-card :file="file" @delete-file="files.splice(i, 1)" class="px-0 ma-0"></csv-card>
      </v-col>
    </v-row>
    <v-row v-for="(file, i) in files" :key="i" :align="'center'" align-content="center" :justify="'center'" no-gutters v-else >
      <v-col>
        <csv-card :file="file" @delete-file="files.splice(i, 1)" class="px-0 ma-0"></csv-card>
      </v-col>
    </v-row>
    <v-row class="d-flex flex-column">
      <v-card class="py-3">
        <v-row class="mx-0">
          <input type="file" accept=".csv" ref="fileInput" name="files" class="d-none" @change="onFileSelected" multiple />
          <v-col cols="12" class="d-flex flex-column align-center" v-if="$vuetify.breakpoint.xs">
            <v-btn class="ma-3" @click="selectFile" text>{{ $t('externalData.selectFile') }}</v-btn>
          </v-col>
          <v-col cols="12" v-else>
            <div class="drag-drop-area pa-3 ma-3" @dragover.prevent @drop.prevent="onDrop">
              <v-btn @click="selectFile" text>{{ $t('externalData.selectOrDropFile') }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>
  
<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import CsvCard from '../card/CsvCard.vue';
export default {
  name: "upload-files-component",
  components: {
    CsvCard
  },
  props: {
    update: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapFields("externalSource", ["externalSource", "selectedDocument", "files"]),
  },
  methods: {
    ...mapActions("alert", ["createAlert"]),
    selectFile() {
      this.$refs.fileInput.click();
    },
    onFileSelected(event) {
      for (const file of event.target.files) {
        this.uploadFile(file)
      }
    },
    onDrop(event) {
      event.preventDefault();
      for (const file of event.dataTransfer.files) {
        this.uploadFile(file)
      }
    },
    uploadFile(file) {
      if (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel') {
        const newInput = {fileName: file.name, file: file, fileSize: file.size};
        this.files.push(newInput);
        this.createAlert({ message: this.$t('message.fileUplaodedSuccess'), type: "success" })
      } else {
        this.createAlert({ message: this.$t('message.wrongFileTypeError'), type: "error" })
      }
    }
  },
};
</script>
<style scoped>
.drag-drop-area {
  border: 2px dashed #bdbdbd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
</style>