<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" sm="6" v-if="$vuetify.breakpoint.xs">
          <exchange-tutorial-carousel :exchange="selectedUserAddress.exchangeType"></exchange-tutorial-carousel>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-row>
          <v-col :align="'center'">
            <h1>{{ $t('connection.connectExchangeTitle') }}{{ selectedUserAddress.exchangeType | BlockchainTypeFormatter }}</h1>
          </v-col>
        </v-row>

        <v-form ref="addPersonalExchangeForm" v-model="validForm" lazy-validation class="my-3">
          <v-row no-gutters>
            <v-col>
              <v-text-field v-model="selectedUserAddress.name" :label="$t('label.name')" solo :rules="nameRules"
                :hint="$t('addAddress.nameFieldHint')" persistent-hint>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field readonly solo
                :value="$options.filters.BlockchainTypeFormatter(selectedUserAddress.exchangeType)" persistent-hint :hint="$t('label.exchange')">
                <template v-slot:prepend-inner>
                  <v-avatar size="25" class="mx-1">
                    <v-img :src="require(`../../assets/blockchain/${selectedUserAddress.exchangeType}.svg`)" contain>
                    </v-img>
                  </v-avatar>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field solo
                v-if="selectedUserAddress.exchangeType != null && $SUPPORTED_EXCHANGE_TRANSACTIONS.includes(selectedUserAddress.exchangeType)"
                v-model="selectedUserAddress.apiKey" :label="$t('label.apiKey')" :hint="$t('addAddress.apiKeyFieldHint')"
                persistent-hint :rules="apiKeyRules" :type="showKey ? 'text' : 'password'"
                :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showKey = !showKey">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field solo
                v-if="selectedUserAddress.exchangeType != null && $SUPPORTED_EXCHANGE_TRANSACTIONS.includes(selectedUserAddress.exchangeType)"
                v-model="selectedUserAddress.apiSecret" :label="$t('label.apiSecret')"
                :hint="$t('addAddress.apiSecretFieldHint')" persistent-hint :rules="apiSecretRules"
                :type="showSecret ? 'text' : 'password'" :append-icon="showSecret ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showSecret = !showSecret">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <add-tag-select
                v-if="selectedUserAddress.exchangeType != null && $SUPPORTED_EXCHANGE_TRANSACTIONS.includes(selectedUserAddress.exchangeType)"></add-tag-select>
            </v-col>
          </v-row>
          <upload-files-component
            v-if="selectedUserAddress.exchangeType != null && !$SUPPORTED_EXCHANGE_TRANSACTIONS.includes(selectedUserAddress.exchangeType)"></upload-files-component>
        </v-form>
        <v-row class="mt-5">
          <v-spacer />
          <v-btn @click="addNewSource" :loading="loading" color="accent" class="white--text" tile>
            {{ $t('addAddress.exchangeButtonText') }}
          </v-btn>
          <v-spacer />
        </v-row>
        <v-divider v-if="$vuetify.breakpoint.xs" class="mt-10"></v-divider>
      </v-col>
      <v-divider :vertical="!$vuetify.breakpoint.xs" class="my-5">
      </v-divider>
      <v-col cols="12" md="6" sm="6" v-if="!$vuetify.breakpoint.xs">
          <exchange-tutorial-carousel :exchange="selectedUserAddress.exchangeType"></exchange-tutorial-carousel>
      </v-col>
    </v-row>
    <v-dialog v-model="loading" width="600px" persistent>
      <v-card class="dialog-top-color" height="300px">
        <v-card-text class="text-center align-center justify-center mt-15">
          <v-progress-circular indeterminate size="100" :width="10" color="accent"></v-progress-circular>
          <p class="mt-5">{{ $t('addAddress.circularProgressSentence') }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import AddTagSelect from "../../components/select/AddTagSelect.vue"
import UploadFilesComponent from '../../components/misc/UploadFilesComponent.vue'
import ExchangeTutorialCarousel from '../../components/carousel/ExchangeTutorialCarousel.vue'
export default {
  name: 'add-exchange-csv-page',
  components: { AddTagSelect, UploadFilesComponent, ExchangeTutorialCarousel },
  data() {
    return {
      loading: false,
      showSecret: false,
      showKey: false,
      showPassphrase: false,
      checkbox: false,
      value: 0,
      validForm: false,
      nameRules: [
        v => !!v || this.$t('addAddress.nameRequiredText')
      ],
      apiKeyRules: [
        v => !!v || this.$t('addAddress.apiKeyRequiredText')
      ],
      apiSecretRules: [
        v => !!v || this.$t('addAddress.apiSecretRequiredText')
      ],
      apiPassphraseRules: [
        v => !!v || this.$t('addAddress.passphraseRequiredText')
      ],
    }
  },
  computed: {
    ...mapFields("selectedUserAddress", ["selectedUserAddress"]),
    ...mapFields("externalSource", ["files"]),
    ...mapFields("loggedUser", ["loggedUser"]),
    getFiscalYear(){
      // Return previous year
      return new Date().getFullYear() - 1
    },
    getExchangeDescription(){
      return this.$t('connection.connectExchangeCsvDescription', {exchange: this.$options.filters.BlockchainTypeFormatter(this.selectedUserAddress.exchangeType)})
    },
    isCsvExchangeAutomatic(){
      return this.$SUPPORTED_EXCHANGE_CSV_AUTOMATIC.includes(this.selectedUserAddress.exchangeType)
    }
  },
  methods: {
    ...mapActions("externalSource", ["uploadMultipleExternalData"]),
    ...mapActions("alert", ["createAlert"]),
    addNewSource() {
      if (!this.$refs.addPersonalExchangeForm.validate()) {
        return
      }
      this.uploadNewData();
    },
    getTemplateReference(exchange) {
      switch (exchange) {
        default:
          return '/template/okipo-template.csv'
      }
    },
    uploadNewData() {
      if (this.files.length == 0) {
        this.createAlert({ message: this.$t('message.externalDataNotUploadedError'), type: "error" })
        return
      }
      this.loading = true
      let filesToUpload = []
      for (const file of this.files){
        filesToUpload.push(file)
      }
      const payload = {
        files: filesToUpload,
        name: this.selectedUserAddress.name,
        exchange: this.selectedUserAddress.exchangeType,
      }
      this.uploadMultipleExternalData(payload).then(() => {
        this.createAlert({ message: this.$t('message.uploadNewSourceSuccess'), type: "success" })
        this.files = []
        this.$router.push("/connection-management")
      }).catch((err) => {
        if (err.response.data && err.response.data.message) {
          if (err.response.data.message == 'EXTERNAL_DATA_NOT_SUPPORTED') {
            this.createAlert({ message: this.$t('message.externalDataNotSupportedError'), type: "error" })
          } else if (err.response.data.message == 'EXTERNAL_DATA_NOT_UPLOADED') {
            this.createAlert({ message: this.$t('message.externalDataNotUploadedError'), type: "error" })
          } else if (err.response.data.message == 'EXTERNAL_DATA_ALREADY_UPLOADED') {
            this.createAlert({ message: this.$t('message.externalDataAlreadyUploadedError'), type: "error" })
          } else {
            this.createAlert({ message: this.$t('message.genericError'), type: "error" })
          }
        } else {
          this.createAlert({ message: this.$t('message.genericError'), type: "error" })
        }
      }).finally(() => {
        this.loading = false
        this.checkbox = false
      });
    },
  },
  created() {
    this.selectedUserAddress = {
      id: null,
      name: null,
      userAddressType: 'PERSONAL',
      apiKey: null,
      apiSecret: null,
      passphrase: null,
      exchangeType: null,
      tag: null,
    }
    if (this.$route.params.exchange) {
      this.selectedUserAddress.exchangeType = this.$route.params.exchange.toUpperCase()
    }
  }
}
</script>

<style>
.v-progress-linear.animate .v-progress-linear__determinate {
  animation: move 2s linear infinite;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100px 100px;
  }
}

.custom-tooltip {
  opacity: var(--v-tooltip-opacity, 1) !important;
  background: var(--v-tooltip-bg, rgb(109, 109, 109)) !important;
}
</style>